import type { DataArrayPartial, Option } from "slim-select/dist/store";
import type { IMarriottLocation } from "../@types";

declare const formAjax: {
    ajaxUrl: string;
};

export async function fetchMarshaCodes(selectField: HTMLSelectElement) {
    const response = await fetch(`${formAjax.ajaxUrl}?action=get_locations`);

    if (!response.ok) {
        throw new Error("Couldn't fetch MARSHA Codes");
    }

    const locations: IMarriottLocation[] = await response.json();

    const { value } = selectField.dataset;

    const selectData: DataArrayPartial = locations.map((location) => {
        return {
            text: `${location.locationNumber} - ${location.locationName}`,
            value: location.locationNumber,
            selected: location.locationNumber === value,
            data: {
                postalCode: location.postalCode,
            },
        };
    });

    return selectData;
}

export function setAddress(option: Option) {
    const { postalCode } = option.data;
    const postalCodeInput = document.querySelector<HTMLInputElement>(
        ".location-address .address_zip input",
    );

    if (postalCodeInput) {
        postalCodeInput.value = postalCode;
    }
}

export function searchLocations(search: string, locations: any[]) {
    const foundLocations: DataArrayPartial = locations.filter((location) =>
        location.text.toLowerCase().includes(search),
    );

    return new Promise<DataArrayPartial>((resolve) => resolve(foundLocations));
}
